<template>
	<div class="app-container">
	
	</div>
</template>

<script>
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				
			}
		},
		beforeMount() {

		},
		methods: {

		}
	}
</script>

<style>
</style>
